import React, { useState } from 'react';
import logo from "./assets/logo.png";

const LinkTree = () => {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const xRotation = ((e.clientY - top - height / 2) / height) * 20; // Vertical tilt
    const yRotation = ((e.clientX - left - width / 2) / width) * -20; // Horizontal tilt
    setRotation({ x: xRotation, y: yRotation });
  };

  const resetRotation = () => {
    setRotation({ x: 0, y: 0 });
  };

  return (
    <div 
      className="container" 
      onMouseMove={handleMouseMove} 
      onMouseLeave={resetRotation}
      style={{
        transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
      }}
    >
      <header>
        <img src={logo} alt="Lucy's Profile" className="profile-pic" />
        <h1>Lucy Pack Magic</h1>
        <p>Welcome to Lucy Pack Magic! Visit my TikTok shop for awesome collection items, and check out the Lucy Pack Magic Rewards app to earn points toward cool rewards! Follow along for epic pulls, exclusive deals, and all things Pokémon!<br /><br /></p>
      </header>
      <div className="links">
        <a href="https://rewards.lucypackmagic.com/" rel="noopener noreferrer" className='purp'>Rewards App</a>
        <a href="https://www.tiktok.com/@lucypackmagic?lang=en" target="_blank" rel="noopener noreferrer">TikTok</a>
        <a href="https://www.youtube.com/@LucyPackMagic" target="_blank" rel="noopener noreferrer">YouTube</a>
        <a href="https://www.instagram.com/lucypackmagic/" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
    </div>
  );
};

export default LinkTree;
